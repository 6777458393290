<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="发布采购"/>
		<PurForm class="purForm" @eventForm="handleGetForm" />
	</div>
</template>

<script>
	import PurForm from '@/components/Purchase/Form'
	import Axios from "axios"
	export default {
		methods:{
			handleGetForm(form) {
				Axios.post('/saveCinfo', form).then(data => {
					let res = data.data
					if (res.code == 200) {
						this.$message({
							message: '发布成功',
							type: 'success'
						})
						this.$router.push('/purchase');
					}else{
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			}
		},
		components: {
			PurForm
		}
	}
</script>